@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Rasa:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  padding: 15px 30px;
  background-color: #0E2279;
  border-radius: 7.5px;
  color: #fff;
  outline: none;
  border: none;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;

  -webkit-tap-highlight-color: transparent;
-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  color: #0E2279;
}

/* a:focus {
  outline: none;
} */

a:visited {
  text-decoration: none;
  color: inherit;
  outline: none
}

.text-decor {
  background-color: #FFC55A;
  width: 10px;
  height: 20px;
  margin-bottom: 0;
}


i, span {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* loading spinner */

.svg-spinner {
  /* align-items: center; */
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
 }
 
 circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 }
 
 @keyframes rotate4 {
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes dash4 {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }
 
  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }
 
  100% {
   stroke-dashoffset: -125px;
  }
 }

 /* end */