.blogmain-div  {
    margin: 20px 30px;
}

.welcomeblog-txt {
    font-size: 30px;
    font-weight: 500;
}

.blogbox-maindiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.blogbox-div {
    padding: 10px 25px;
    width: 400px;
    background-color: rgb(255, 255, 255);
    border-radius: 7.5px;
    cursor: pointer;
}

.blogbox-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.blogbox-title {
     font-size: 20px;
     font-weight: 500;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 3; /* Number of lines to show */
     overflow: hidden;
     text-overflow: ellipsis;
}



@media only screen and (max-width: 1000px) {
    .blogbox-div{ 
        width: 40%;
    }
}

@media only screen and (max-width: 700px) {
    .blogbox-div{ 
        width: 100%;
    }
}