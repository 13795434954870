.gallery-maindiv {
  margin: 20px 90px;
}

.galleryimg-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.gallery-txt {
    font-size: 35px;
    font-weight: 600;
    color: #0e2279;
    margin-bottom: 0;
    margin-top: 0;
}

.gallerypage-img {
  height: 400px;
  /* width: 100%; */
  object-fit: cover;
}


@media only screen and (max-width: 900px) {
  .gallery-maindiv {
      margin: 20px 30px;
  }
}

@media only screen and (max-width: 600px) {
  .gallery-maindiv {
    margin: 20px 20px;
  }

  .gallery-txt {
    margin-bottom: 4px;
    font-size: 30px;
  }

  .picturengo-txt {
    width: 100%;
  }
}



/* ANIMATION  */
@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
