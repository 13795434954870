.programs-maindiv {
    margin: 10px 50px;
}

.programflex-div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.program-introtxt {
    font-size: 30px;
    font-weight: 500;
}

.programlist-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #F8F8F8;
    padding: 5px 20px;
    width: 610px;
    height: 200px;
}

.programs-title {
    color: #0E2279;
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 10px;
}

.program-img {
    height: 120px;
    width: 120px;
    object-fit: cover;
}

.program-subtitle {
    font-size: 13px;
    color: gray;
    margin-top: 0px;
}


@media only screen and (max-width: 1150px) {
    .programflex-div {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 900px) { 

    .programflex-div {
        display: flex;
        flex-direction: column;
    }

    .programlist-div {
        width: 100%;
        max-width: fit-content;
    }
}


@media only screen and (max-width: 600px) {
    
    .programflex-div {
        display: flex;
        flex-direction: column;
    }
    
    .programs-maindiv {
        margin: 10px 12px;
    }
    
    .program-introtxt {
        font-size: 20px;
    }
    
    .programlist-div {
        padding: 2px 10px;
        height: 300px;
    }

    .programs-title {
        font-size: 15px;
    }

    .program-subtitle {
        font-size: 11px;
    }
}