.edit-deletebtn {
    display: flex;
    justify-content: space-between;
}

.deletepost-btn {
    /* background-color: red; */
}

.editpost-btn{
    /* background-color: green; */
}