.programdetails-div {
    margin: 20px 50px;
}

.flexprogramdiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.programdetails-title {
    font-size: 40px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 17px;
    text-align: center;
    line-height: 1.2;
}

.dateandauthor {
    color: gray;
}

.dateandauthor p {
    margin: 10px;
}

.depathway-programstxt {
    color: #0E2279;
    margin-bottom: 0;
}

.program-details-img {
    max-width: 100%;
    height: 400px;
    width: 700px;
    object-fit: cover;
    margin-top: 0;
}

.programdetails-imgdiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.programdetailsinner-div {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.programdetails-subtext {
    /* width: 56%; */
    text-align: justify;
}

.donatelink {
    font-weight: 900;
    font-size: 20px;
    color: #f0a525 !important;
}

@media only screen and (max-width: 1200px) {
    .programdetailsinner-div{
        width: 75%;
    }
}

@media only screen and (max-width: 890px) {


    .programdetails-title {
        font-size: 28px;
    }

    .programdetails-div {
        margin: 10px 30px;
    }

    .program-details-img {
        /* width: 500px; */
    }

    .programdetailsinner-div{
        width: 100%;
    }
}