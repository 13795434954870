.boardmembers-div { 
    padding: 20px 90px;
    background-color: rgb(248, 248, 248);
}

.meetboard-txt { 
    font-size: 35px;
    text-align: center;
    font-weight: 600;
    color: #0e2279;
    margin-top: 100px;
}

.members-div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.memberdetails-div {
    display: flex;
    margin-bottom: 70px;
    /* gap: 10px; */
    /* flex-wrap: wrap; */
}

.members-picture {
    height: 300px;
    width: 350px;
    object-fit: cover;
    margin-right: 30px;
}

.member-name {
    font-weight: 600;
    margin-bottom: 0px;
}

.member-position {
    margin-top: 0px;
    color: gray;
}

.member-introdiv {
    max-width: 1000px;
}

.member-intro {
    font-size: 17px;
    text-align: justify;
    padding: 0 10px;
    margin: 0;
}

.line-div {
    height: 1.1px;
    background-color: grey;
}

@media only screen and (max-width: 1300px) {
    .boardmembers-div { 
        padding: 20px 40px;
    }
 }

@media only screen and (max-width: 900px) { 
    .memberdetails-div {
        justify-content: center;
        flex-wrap: wrap;
   }

   .members-picture {
    margin: 0;
   }

   .members-div {
    justify-content: center;
   }

   .meetboard-txt {
    font-size: 27px;
}
   .member-introdiv {
    /* max-width: fit-content; */
   }
}

@media only screen and (max-width: 350px) { 
    .members-picture {
        width: 270px;
    }

    .member-intro {
        padding: 0 10px;
    }


}
