.publish-form {
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;
  gap: 15px;
  margin: 5px 10px;
}

.publish-logo{
    height: 60px;
}

.publish-titletxt {
  height: 50px;
  font-size: 24px;
  /* border-radius: 10px; */
  padding: 0 10px;
  border: 1px solid #c0c0c0;
}

.create-newpostdiv {
     padding:0 10px;
     background-color: #fafafa;
     margin: 0;
     height: 70px;
     display: flex;
     justify-content: space-between;
     align-items: center;
}

input:focus {
  outline: none;
}

.createnewpost-txt {
    margin: 0;
    color: #000000;
    font-size: 20px;
}


