.welcome-txt {
  font-size: 40px;
  margin-bottom: 10px;
  line-height: 45px;
  /* color: #00215E; */
  font-weight: 600;
  width: 70%;

  /** TEXT GRADIENT */
  color: #062D87;

  -webkit-animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
	        animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;


}

.intro-top-div {
  /* background-color: #f9fbff; */
}

.welcomeetxt-subtext {
  font-size: 20px;

  -webkit-animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.intro-maindiv {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* margin-left: 90px; */
  /* margin-top: 40px; */
}

/* .intro-top-div {
    display: flex;
} */

.intro-textdiv {
  margin: 0 70px;
  /* position: absolute; */
  bottom: 50px;
  color: #000000;
}

.intro-imgdiv {
  overflow: hidden;
}

.intro-img {
  object-fit: cover;
  filter: brightness(1);
  background-size: cover;
  width: 100%;
  height: 100%;

  -webkit-animation: kenburns-top 5s ease-out both;
  animation: kenburns-top 5s ease-out both;
}

@media only screen and (max-width: 380px) {
  .welcome-txt {
    font-size: 15px;
    width: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  .welcome-txt {
    font-size: 45px;
    margin-bottom: 50px;
    width: 100%;
    line-height: 35px !important;
    margin-bottom: 0;
  }

  .welcomeetxt-subtext {
    font-size: 20px;
  }

  .intro-textdiv {
    margin: 0 20px;
  }

  .intro-img {
    filter: brightness(1);
    background-size: cover;
    width: 100%;
    height: 500px;
  }

  .intro-maindiv {
    flex-direction: column;
  }
}

@media only screen and (max-width: 900px) {
    .intro-maindiv {
        /* flex-direction: column; */
    }

    .welcome-txt {
        font-size: 30px;
        width: 75%;
    }

    .intro-textdiv {
        margin: 0 20px;
        /* position: absolute; */
        bottom: 50px;
        color: #000000;
      }


}

@media only screen and (max-width: 1200px) {
  .welcomeetxt-subtext {
    font-size: 20px;
  }

  .intro-img {
    filter: brightness(1);
    background-size: cover;
    width: 100%;
    height: 500px;
  }
}

/* WEBKIT  */

@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 50%; /* Center the scaling */
    transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: 50% 50%; /* Center the scaling */
    transform-origin: 50% 50%;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 50%; /* Center the scaling */
    transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: 50% 50%; /* Center the scaling */
    transform-origin: 50% 50%;
  }
}



/* WEBKIT  */
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
