.contact-forminputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contactform-text {
    text-align: center;
    font-size: 30px;
}

.contactform-name {
    display: flex;
    gap: 40px;
}

.contactform-namediv {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contactform-section {
    margin: 10px 70px;
}

.contact-forminputs input {
    background-color: #f7f3ff;
    height: 40px;
    /* border-radius: 10px; */
    border: 1px solid #666666;
    padding: 0px 10px;
    font-size: 15px;
}

.contactform-submit {
    margin-top: 20px;
}

.required::after {
    content: ' *';
    color: red; 
}

.contact-txtbtm {
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 20px;
    margin-top: 70px;
}

.contacttxt-innerdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    text-align: center;
    gap: 20px;
}


@media only screen and (max-width: 600px) {
    .contactform-text {
        font-size: 30px;
    }

    .contactform-section {
        margin: 10px 10px;
    }

    .contactform-name {
        flex-direction: column;
        gap: 10px;
    }
}