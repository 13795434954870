.campaign-maindiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.campaign-maindiv div {
    width: 200px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFF;
    font-size: 20px;
}

.campaign-numbers  {
    font-weight: 800;
    font-size: 30px;
    margin-bottom: 0;
}

.campaign-orange {
    background-color: #062D87;
}

.campaign-yellow {
    background-color: #FFC55A;
}


@media only screen and (max-width: 600px) {
    .campaign-maindiv div {
        width: 140px;
        height: 120px;
        font-size: 15px;
    }
}  