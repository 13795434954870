.aboutus-text {
    font-size: 35px;
    font-weight: 600;
    color: #0E2279;
    margin-bottom: 0;
    margin-top: 0;
}

.textdesign-div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 70px;
}

.aboutus-p {
    font-size: 20px;
    text-align: justify;
}

.aboutus-maindiv {
    margin: 20px 90px;
}

.about-sideimg {
    height: 400px;
}

.aboutinner-flex {
    display: flex;
    gap: 50px;
}

@media only screen and (max-width: 900px) {
    .aboutus-maindiv {
        margin: 20px 30px;
        text-align: justify;
    }
    
    .about-sideimg {
        max-width: 100%;
        height: auto;
    }
    
    .aboutinner-flex { 
        flex-wrap: wrap;
        gap: 20px;
    }
}

@media only screen and (max-width: 600px) {
    
    .aboutus-maindiv {
        margin: 20px 20px;
        text-align: justify;
    }

    .aboutus-p {
        margin-top: 0;
    }

    .aboutus-text {
        margin-bottom: 4px;
        font-size: 30px;
    }

}