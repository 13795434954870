.aboutuspageheader-txt {
    font-size: 35px;
    font-weight: 600;
    color: #0E2279;
}

.organ-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  }

  .organ-img {
    max-width: 100%;
    height: auto;
  }

  .organs-info {
    text-align: justify;
  }



@media only screen and (max-width: 600px) {

    .aboutuspageheader-txt  {
        margin-bottom: 0;
        font-size: 30px;
    }

    .about-us-p {
        /* margin-top: 0; */
    }
}