.navbar-div {
    display: flex;    
    align-items: center;
    justify-content: space-between;
    background-color: #000000;
    padding: 0 40px;
}

.upper-navbar {
    display: flex;
    justify-content: space-around;
    background-color: #000000;
}

.innerlinks-div, .innerlinks-diver {
    display: flex;
    gap: 70px;
}

.nav-links {
    display: flex;
    gap: 50px;
    color: #FFF;

}

.nav-links p:hover {
    color: #F0A525;
}

.logo {
    height: 80px;
}

.menu-icon{
    display: none;
    color: #FFF;
}

.Btn {
    width: 140px;
    height: 45px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to right,#77530a,#ffd277,#77530a,#77530a,#ffd277,#77530a);
    background-size: 250%;
    background-position: left;
    color: #ffd277;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 1s;
    overflow: hidden;
  }
  
  .Btn::before {
    position: absolute;
    content: "Donate";
    color: #ffd277;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    height: 90%;
    border-radius: 8px;
    transition-duration: 1s;
    background-color: rgba(0, 0, 0, 0.842);
    background-size: 200%;
  }
  
  .Btn:hover {
    background-position: right;
    transition-duration: 1s;
  }
  
  .Btn:hover::before {
    background-position: right;
    transition-duration: 1s;
  }
  
  .Btn:active {
    transform: scale(0.95);
  }

@media only screen and (max-width: 1000px) {
    .upper-navbar {
        display: block;
    }
    .menuandlogo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 29px;
    }
    
    .menu-icon {
        font-size: 40px;
        cursor: pointer;
        /* display: flex; */
        display: inline-block;
    }

    .navbar-div {
        flex-direction: column;
        padding-bottom: 0px;
    }
    
    .innerlinks-div  {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        width: 100%;
    }

    .innerlinks-diver {
        display: none;
    }

    .nav-links {
        flex-direction: column;
        gap: 2px;
        /* display: inline-block; */
        margin-bottom: 20px;
        width: 100%;
    }
    
    .nav-links a {
        /* border: 1px solid #a1a1a1; */
        height: 40px;

    }

    .btn-div {
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 600px) {

    .navbar-div {
        padding: 0 10px;
    }

    .logo {
        height: 70px;
    }

    .donate-btn {
        height: 40px;
        width: 90px;
        padding: 0;
    }

    .nav-links {
        font-size: 14px;
        /* gap: 30px; */
    }
}

  