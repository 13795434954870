.footer-main-main {
    background-color: #F8F8F8;

}

.footer-main {
    color: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #F8F8F8;
    padding: 10px 35px;
    margin-top: 100px;
    /* position:fixed;
    width: 100%;
    bottom:0; */
}

.footer-logo {
    height: 70px;
}

.logoandname {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 0px;
    /* align-items: center; */
}

.logo-txt {
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 10px;
}

.logo-para {
    margin-top: 5px;
    font-size: 14px;
}

.sociallinks-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    /* text-align: center; */
}

.social-txt { 
    font-weight: 600;
}

.social-links {
    display: flex;
    gap: 20px;
}

.copyright {
    text-align: center;
    margin-top: 50px;
    margin-bottom:0;
    padding-bottom: 10px;
}

.seperate-div {
    display: flex;
}

.contactandicon {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.contactus-txt {
    font-weight: 600;
}

.contactmain-div {
    width: 400px;

}

@media only screen and (max-width: 900px) {
    .contactmain-div {
        margin-top: 20px;
    }

    .logoandname {
        width: auto;
    }
}
